import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["monthDisplay", "scheduleContainer", "display"];


    connect() {
        this.events = [];
        // data-schedule 属性が有効なJSONであるか試す
        try {
            const scheduleData = JSON.parse(this.scheduleContainerTarget.dataset.schedule || '[]'); // 無効または空の場合、空の配列を使用
            this.events = scheduleData.map(item => ({
                name: item[0],
                startDate: item[1],
                endDate: item[2],
                type: "auction"
            }));
        } catch (error) {
            console.error("Error parsing schedule data:", error);
            // エラーが発生した場合でもカレンダーは表示される
        }

        const d = new Date();
        // カレンダーの生成
        this.generateCalendar(d);
        this.updateMonthDisplay(d.getMonth(), d.getFullYear())

        // イベントデータが存在する場合のみイベント説明を表示
        if (this.events.length > 0) {
            this.createEventDescriptions();
        }
    }

    showPreviousMonth() {
        const textContent = this.monthDisplayTarget.textContent;
        const yearMatch = textContent.match(/(\d+)年/);
        const monthMatch = textContent.match(/(\d+)月/);

        if (yearMatch && monthMatch) {
            let year = parseInt(yearMatch[1]);
            let month = parseInt(monthMatch[1]) - 1; // 0から始まる月番号に変換

            month--; // 前月
            if (month === -1) { // 1月なら前年12月に
                month = 11;
                year--;
            }

            this.generateCalendar(new Date(year, month));
            this.updateMonthDisplay(month, year);
        } else {
            console.error("Invalid month display format:", textContent);
        }
    }

    showNextMonth(){
        const textContent = this.monthDisplayTarget.textContent;
        const yearMatch = textContent.match(/(\d+)年/);
        const monthMatch = textContent.match(/(\d+)月/);

        if (yearMatch && monthMatch) {
            let year = parseInt(yearMatch[1]);
            let month = parseInt(monthMatch[1]) - 1; // 0から始まる月番号に変換

            month++; // 翌月
            if (month === 12) { // 12月なら翌年1月に
                month = 0;
                year++;
            }

            this.generateCalendar(new Date(year, month));
            this.updateMonthDisplay(month, year);
        } else {
            console.error("Invalid month display format:", textContent);
        }
    }

    updateMonthDisplay(month, year) {
        this.monthDisplayTarget.textContent = `${year}年${month + 1}月`;
    }

    generateCalendar(d) {
        const today = d
        const month = today.getMonth();
        const year = today.getFullYear();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, month, 1).getDay();

        let calendarHTML = `<table class="calendar-table"><thead><tr>`;
        const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
        daysOfWeek.forEach(day => calendarHTML += `<th>${day}</th>`);
        calendarHTML += `</tr></thead><tbody><tr>`;

        for (let i = 0; i < firstDayOfMonth; i++) {
            calendarHTML += `<td class="padding-day"></td>`;
        }

        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(year, month, day);
            currentDate.setHours(0,0,0,0);
            let eventClass = "";

            this.events.forEach((event, index) => {
                const startDate = new Date(event.startDate);
                startDate.setHours(0,0,0,0);
                const endDate = new Date(event.endDate);
                endDate.setHours(23,59,59,999);

                if (startDate <= currentDate && currentDate <= endDate) {
                    eventClass = `event-color-${(index % 4) + 1}`;
                }
            });

            calendarHTML += `<td class="${eventClass}">${day}</td>`;
            if ((firstDayOfMonth + day - 1) % 7 === 6 && day < daysInMonth) {
                calendarHTML += `</tr><tr>`;
            }
        }

        calendarHTML += `</tr></tbody></table>`;
        this.displayTarget.innerHTML = calendarHTML;
        //this.updateMonthDisplay(month, year)

    }

    createEventDescriptions() {
        let descriptionsHTML = '<div class="event-descriptions">';

        this.events.forEach((event, index) => {
            const colorClass = `event-color-${(index % 4) + 1}`;
            descriptionsHTML += `<div class="event-description">
                                    <span class="color-swatch ${colorClass}"></span>
                                    <span>${event.name}</span>
                                  </div>`;
        });

        descriptionsHTML += '</div>';
        this.element.innerHTML += descriptionsHTML;
    }
}

import {Controller} from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["time"]

  connect() {
    this.totalSeconds = parseInt(this.timeTarget.textContent);
    this.language = document.documentElement.lang;

    // 既存のインターバルがあればクリアする
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.totalSeconds == -1) {
      this.timeTarget.classList.remove("hidden-at-start");
      this.displayBeforeStartMessage();
    } else if (this.totalSeconds == 0) {
      this.timeTarget.classList.remove("hidden-at-start");
      this.displayEndMessage();
    } else {
      setTimeout(() => {
        this.timeTarget.classList.remove("hidden-at-start");
      }, 1000);
      this.startCountdown();
    }
   // this.updatePrice(); // 価格更新のポーリングを開始
  }

  updatePrice() {
    setInterval(() => {
      fetch("/products/update_bid", {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        }
      })
          .then(response => {
            if (response.ok) {
              return response.text();
            }
            throw new Error('Network response was not ok.');
          })
          .then(html => {
            Turbo.renderStreamMessage(html);
          })
          .catch(error => console.error("Failed to fetch update: ", error));
    }, 10000); // 15秒ごとに価格情報の更新を試みる
  }


  startCountdown() {
    this.interval = setInterval(() => {
      if (this.totalSeconds > 0) {
        this.totalSeconds--;
        this.updateDisplay();
      } else {
        clearInterval(this.interval);
        this.displayEndMessage(); // 終了メッセージを表示
      }
    }, 1000);
  }

  displayBeforeStartMessage() {
    if (this.language === 'ja') {
      this.timeTarget.textContent = "開始前";
    } else {
      this.timeTarget.textContent = "Before Auction Start";
    }
  }

  displayEndMessage() {
    if (this.language === 'ja') {
      this.timeTarget.textContent = "終了";
      //this.updatePrice(); // 価格更新のポーリングを開始
    } else {
      this.timeTarget.textContent = "Ended";
    }
  }

  updateDisplay() {
    let days = Math.floor(this.totalSeconds / 86400);
    let hours = Math.floor((this.totalSeconds % 86400) / 3600);
    let minutes = Math.floor((this.totalSeconds % 3600) / 60);
    let seconds = this.totalSeconds % 60;

    if (this.language === 'ja') {
      this.timeTarget.textContent = `${days}日 ${hours}時間 ${minutes}分 ${seconds}秒`;
    } else {
      this.timeTarget.textContent = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }
  }

  pad(number) {
    return number.toString().padStart(2, '0');
  }

  disconnect() {
    clearInterval(this.interval);
  }
}

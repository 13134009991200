// app/javascript/controllers/radio_redirect_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radio"]

  connect() {
    console.log("RadioRedirectController connected");
  }

  redirect(event) {
    const radioValue = event.target.value; // Get the value of the selected radio button
    const baseUrl = this.data.get("baseUrl"); // Get the base URL from a data attribute
    const fullUrl = `${baseUrl}?subcategory=${encodeURIComponent(radioValue)}`; // Construct the full URL with the radio button's value as a query parameter
    window.location = fullUrl; // Redirect to the constructed URL
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["price"]

    trimComma(event) {
        event.preventDefault(); // デフォルトの送信を阻止
        let formattedValue = this.priceTarget.value.replace(/,/g, ''); // カンマを削除
        formattedValue = formattedValue.replace(/^JPY\s*/, ''); // "JPY " を削除（先頭から）
        this.priceTarget.value = formattedValue;
        this.element.submit(); // フォームの送信を続行
    }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        url: String
    }
    connect() {
        // 価格更新のポーリングを開始
        this.updatePrice();
    }
    updatePrice() {
        setInterval(() => {
            // キー名をキャメルケースで指定
            fetch(this.urlValue + "/update_bid/", {
                headers: {
                    'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    }
                    throw new Error('Network response was not ok.');
                })
                .then(html => {
                    Turbo.renderStreamMessage(html);
                })
                .catch(error => console.error("Failed to fetch update: ", error));
        }, 30000); // 5秒ごとに価格情報の更新を試みる
    }
}

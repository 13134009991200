import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["input", "list"];
    initialize() {
        // contentUpdatedイベントリスナーを追加
        this.handleContentUpdated = this.filter.bind(this);
    }

    connect() {
        console.log("FilterController connected");
        this.element.addEventListener('contentUpdated', this.handleContentUpdated);
        this.filter();  // 初期フィルタリングを行う
    }

    disconnect() {
        this.element.removeEventListener('contentUpdated', this.handleContentUpdated);
    }

    filter() {
        console.log("Filter function called");
        const filterValue = this.inputTarget.value.toLowerCase();
        const items = Array.from(this.listTarget.getElementsByClassName("like-li"));
        items.forEach((item) => {
            const title = item.querySelector('.filter_title').dataset.filterTarget.toLowerCase();
            console.log(title.includes(filterValue))
            if (title.includes(filterValue)) {
                item.style.display = "";
            } else {
                item.style.display = "none";
            }
        });
    }
}

import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["price"]

    connect() {
        this.syncStepValue();
        this.updateDisplay();
    }

    increment() {
        let value = Number(this.priceTarget.value) || 1000; // 文字列を数値化
        let step = Number(this.priceTarget.getAttribute('step'));
        value += step;
        this.priceTarget.value = Math.max(1000, value); // 1000円未満の値は1000円に設定
    }

    decrement() {
        let value = Number(this.priceTarget.value) || 1000; // 文字列を数値化
        let step = Number(this.priceTarget.getAttribute('step'));
        value -= step;
        this.priceTarget.value = Math.max(1000, value); // 1000円未満にならないように制限
    }

    updateStep(event) {
        this.priceTarget.setAttribute('step', event.target.value);
        this.syncStepValue();
    }

    syncStepValue() {
        let selector = document.getElementById('step-selector');
        this.priceTarget.setAttribute('step', selector.value);
        this.updateDisplay();
    }

    updateDisplay() {
        let value = Number(this.priceTarget.value) || 1000;
        this.priceTarget.value = Math.max(1000, value); // 初期表示も1000円未満は1000円に設定
    }
}


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount"]
  isComposing = false;

  connect() {
    this.amountTargets.forEach(target => {
      target.addEventListener('compositionstart', () => {
        this.isComposing = true;
      });
      target.addEventListener('compositionend', () => {
        this.isComposing = false;
        this.formatInput(target);
      });
      target.addEventListener('input', () => {
        if (!this.isComposing) {
          this.formatInput(target);
        }
      });
    });
  }

  formatInput(target) {
    const value = target.value.replace(/,/g, '');
    const formattedValue = this.formatNumber(value);
    target.value = formattedValue;
  }

  formatNumber(value) {
    if (!value.match(/^\d+$/)) return value; // 数字以外の入力はそのまま返す
    return parseInt(value, 10).toLocaleString();
  }

  trimComma() {
    this.amountTargets.forEach(target => {
      target.value = target.value.replace(/,/g, '');
    });
  }
}
